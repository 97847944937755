<template>
  <div id="home">
    <site-header :title="translate.global.exhibit_title[language] + '<br />' + translate.global.exhibit_subtitle[language]"/>
    <b-container fluid class="pb-3 bg-white">
      <div class="row">
        <div class="col-lg-8" v-html="'<h1>' + title + '</h1>' + content"></div>
        <div class="col-lg-4 text-center side-content" id="aside-home">
          <DefaultAside />
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import header from '@/components/Header.vue'
import DefaultAside from '@/components/partials/DefaultAside.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'about',
  components: {
    DefaultAside,
    'site-header': header
  },
  data () {
    return {
      pageIds : {
        de: 82,
        en: 86,
        pl: 89
      }
    }
  },
  computed: {
    ...mapGetters({
      language: 'language',
      translate: 'translate/translate',
      pages: 'wordpress/pages'
    }),
    title: function () {
      let content
      if (this.pages && typeof this.pages !== 'undefined') {
        content = this.pages.find(page => page.id === this.pageIds[this.language])
      }
      if (content) { content = content.title.rendered }
      return content
    },
    content: function () {
      let content
      if (this.pages && typeof this.pages !== 'undefined') {
        content = this.pages.find(page => page.id === this.pageIds[this.language])
      }
      if (content) { content = content.content.rendered }
      return content
    }
  }
}
</script>